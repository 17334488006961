import { match, RouteComponentProps } from 'react-router'
import queryString from 'query-string'
import TranslationStatus from '../../redux/modules/filter/TranslationStatus'
import State from '../../redux/modules/State'
import OptionalProjectRouteProps from './OptionalProjectRouteProps'
import { Location } from 'history'

const getFilterFromLocation = (location: Location) => {
  const queryParams = queryString.parse(location.search)
  const statusString = queryParams['status']
  return statusString === TranslationStatus.COMPLETED
    ? TranslationStatus.COMPLETED
    : TranslationStatus.OPEN
}

const getPlatformFromMatch = (match: match<OptionalProjectRouteProps>) => {
  const projectId = match.params.projectId
  if (!projectId) {
    return ''
  }
  return projectId
}

const getPageFromLocation = (location: Location) => {
  const queryParams = queryString.parse(location.search)
  let project = queryParams['page'] as string
  if (!project) {
    project = '0'
  }
  const projectInt = parseInt(project)
  return isNaN(projectInt) ? 0 : projectInt
}

const getCurrentFilter = (
  state: State,
  ownProps: RouteComponentProps
): TranslationStatus => getFilterFromLocation(ownProps.location)

const getCurrentPlatform = (
  state: State,
  ownProps: RouteComponentProps<OptionalProjectRouteProps>
): string => getPlatformFromMatch(ownProps.match)

const getCurrentPage = (state: State, ownProps: RouteComponentProps): number =>
  getPageFromLocation(ownProps.location)

const getSearchQuery = (
  state: State,
  ownProps: RouteComponentProps
): string | undefined => {
  const queryParams = queryString.parse(ownProps.location.search)
  return queryParams['q'] as string | undefined
}

export {
  getFilterFromLocation,
  getCurrentFilter,
  getCurrentPlatform,
  getCurrentPage,
  getPlatformFromMatch,
  getPageFromLocation,
  getSearchQuery,
}
