import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { Redirect } from 'react-router'
import Props from './props/Props'

const DisabledAccountComponent: React.FunctionComponent<Props> = (
  props: Props
) => {
  const state: any = props.location!!.state
  const { from } = state || { from: { pathname: '/' } }

  if (props.isActive || !props.isAuthenticated) {
    return <Redirect to={from} />
  }

  return (
    <Container fluid>
      <Row className="mt-2">
        <Col>
          <h2>Disabled Account: {props.email}</h2>
          <p>
            Please contact Rome2rio and request access to the Translation Tool
          </p>
          <Button onClick={props.logout}>Logout</Button>
        </Col>
      </Row>
    </Container>
  )
}

export default DisabledAccountComponent
