import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import State from '../../../redux/modules/State'
import TranslationDetail from './TranslationDetail'
import StateProps from './props/StateProps'
import { getPlatformIsLoading } from '../../../redux/modules/platforms/selectors'
import { getUserLanguage } from '../../../redux/modules/user/selectors'
import OwnProps from './props/OwnProps'
import DispatchProps from './props/DispatchProps'
import { onStringUpdated } from '../../../redux/modules/translations/actions'
import {
  getNextTranslation,
  getPreviousFilter,
  getPreviousProject,
  getTranslation,
  isNextOnSubmit,
} from './selectors'

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  return {
    isNextOnSubmit: isNextOnSubmit(state, ownProps),
    translation: getTranslation(state, ownProps),
    nextTranslation: getNextTranslation(state, ownProps),
    isLoading: getPlatformIsLoading(state, ownProps),
    previousFilter: getPreviousFilter(state, ownProps),
    previousProject: getPreviousProject(state, ownProps),
    languageCode: getUserLanguage(state),
  }
}

const mapDispatchToProps: DispatchProps = {
  submitTranslation: onStringUpdated,
}

export default withRouter(
  connect<StateProps, DispatchProps, OwnProps, State>(
    mapStateToProps,
    mapDispatchToProps
  )(TranslationDetail)
)
