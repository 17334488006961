import React from 'react'
import Props from './props/Props'
import './style.css'

const SimilarTranslations: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <ul className="list-unstyled mb-0">
      {props.similarTranslations.map((translation) => {
        return (
          <li
            key={`${translation[0]} ${translation[1]}`}
            className="mt-2 text-muted small small-line-height"
          >
            <p className="mb-0 mt-0 ">{translation[0]}</p>
            <b>{translation[1]}</b>
          </li>
        )
      })}
    </ul>
  )
}

export default SimilarTranslations
