import React from 'react'
import Badge from 'react-bootstrap/Badge'
import moment from 'moment'
import TranslationTableRowProps from './TranslationTableRowProps'

const TranslationTableRow: React.FunctionComponent<TranslationTableRowProps> = (
  props: TranslationTableRowProps
) => {
  let badge = null
  if (props.status === 'flagged') {
    badge = <i className="material-icons md-dark">info_outline</i>
  }

  return (
    <tr
      id={props.translationId}
      tabIndex={0}
      onKeyPress={(event) => {
        if (
          event.key === 'Enter' &&
          document.activeElement!!.id === props.translationId
        ) {
          // Only react to the enter key if we are the current active
          // element
          event.preventDefault()
          event.stopPropagation()
          props.onSelectTranslation(props.projectId, props.translationId)
        }
      }}
      onClick={() =>
        props.onSelectTranslation(props.projectId, props.translationId)
      }
    >
      <td className="minSmall mx-auto">{badge}</td>
      <td className="text-truncate">{props.originalString}</td>
      <td className="text-truncate">{props.translatedString}</td>
      <td className="min">
        <Badge variant="secondary">{props.projectId}</Badge>
      </td>
      <td className="min">
        {moment(props.createdDate)
          .fromNow()}
      </td>
    </tr>
  )
}

export default TranslationTableRow
