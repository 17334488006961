import { createSelector } from 'reselect'
import { getCurrentPlatform } from '../../../util/route/filterSelector'
import PlatformsState from './PlatformsState'
import State from '../State'

const getPlatformState = (state: State): PlatformsState => state.platformsState

const getIsLoadingFromPlatform = (
  currentPlatform: string,
  platforms: PlatformsState
) => {
  if (!currentPlatform || currentPlatform === 'all') {
    if (Object.keys(platforms.platforms).length === 0) {
      return true
    } else {
      return Object.keys(platforms.platforms)
        .map((key) => platforms.platforms[key].isLoading)
        .some((isLoading) => isLoading)
    }
  } else {
    const platform = platforms.platforms[currentPlatform]
    if (platform) {
      return platform.isLoading
    } else {
      // Platform doesn't exist yet
      return true
    }
  }
}

const getPlatformIsLoading = createSelector(
  getCurrentPlatform,
  getPlatformState,
  (currentPlatform, platforms) =>
    getIsLoadingFromPlatform(currentPlatform, platforms)
)

export { getPlatformIsLoading, getIsLoadingFromPlatform }
