import { connect } from 'react-redux'
import State from '../../redux/modules/State'
import AppStateProps from './props/AppStateProps'
import AuthenticationStatus from '../../api/model/AuthenticationStatus'
import { withRouter } from 'react-router'
import TranslationsAppComponent from './App'
import { getIsActive } from '../../redux/modules/user/selectors'

const mapStateToProps = (state: State): AppStateProps => {
  return {
    isWaitingForAuthentication:
      state.userState.status === AuthenticationStatus.WAITING ||
      (state.userState.status === AuthenticationStatus.AUTHENTICATED &&
        state.userState.user === undefined),
    isAuthenticated:
      state.userState.status === AuthenticationStatus.AUTHENTICATED,
    isActive: getIsActive(state),
  }
}

export default withRouter(
  connect<AppStateProps, {}, any, State>(mapStateToProps)(
    TranslationsAppComponent
  )
)
