import State from '../State'
import { createSelector } from 'reselect'
import { getCurrentPlatform } from '../../../util/route/filterSelector'
import { getPlatformStateSet } from '../translations/selectors'
import TranslatableString from '../../../api/model/TranslatableString'

const getSearchResults = (state: State) => state.searchState.searchResults

const getSearchQuery = (state: State) => state.searchState.query

const getSearchResultsTranslations = createSelector(
  getCurrentPlatform,
  getPlatformStateSet,
  getSearchResults,
  (platformId: string, translationState, results) => {
    if (!results) {
      return []
    }

    return results
      .filter(
        ([translationPlatform]) =>
          !platformId || translationPlatform === platformId
      )
      .map(([translationPlatform, translationId]) => {
        const platform = translationState[translationPlatform]
        if (platform) {
          return platform[translationId]
        } else {
          return null
        }
      })
      .filter((it) => it !== null) as TranslatableString[]
  }
)

export { getSearchQuery, getSearchResults, getSearchResultsTranslations }
