import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import TranslationsFilter from './Filter/TranslationsFilter'
import TranslationTable from '../../../components/TranslationTable/TranslationTable'
import Props from './props/Props'
import TranslationsAppHeader from '../../../components/Header/TranslationsAppHeader'
import Loading from '../../../components/Loading/Loading'
import TranslationEmpty from './TranslationsEmpty/TranslationsEmpty'

const TranslationList: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div>
      <TranslationsAppHeader {...props} />
      <Container fluid={true}>
        <Row>
          <Col className="justify-content-md-center mb-5">
            <h3 className="mt-5 mb-4">Translations</h3>
            <TranslationsFilter {...props} />
            {props.isLoading && <Loading />}
            {!props.isLoading && props.translations.length <= 0 && (
              <TranslationEmpty />
            )}
            {!props.isLoading && props.translations.length > 0 && (
              <TranslationTable {...props} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TranslationList
