import React from 'react'

const SearchEmpty = () => {
  return (
    <div className="mt-5 d-flex flex-column justify-content-center">
      <strong className="align-self-center">
        No results matched your search
      </strong>
    </div>
  )
}

export default SearchEmpty
