import { connect } from 'react-redux'
import SimilarTranslations from './SimilarTranslations'
import StateProps from './props/StateProps'
import State from '../../../../redux/modules/State'
import OwnProps from './props/OwnProps'
import { makeSearchSelector } from './selectors'

const makeMapStateToProps = () => {
  const searchSelector = makeSearchSelector()
  return (state: State, props: OwnProps): StateProps => {
    const languageCode = state.userState.user!!.languageCode
    return {
      similarTranslations: searchSelector(state, props).map(
        (translation): [string, string] => {
          return [
            translation.originalString,
            translation.translations[languageCode].translatedString,
          ]
        }
      ),
    }
  }
}

// TODO: De-containerify this. This should be a component.
export default connect<StateProps, {}, OwnProps, State>(makeMapStateToProps)(
  SimilarTranslations
)
