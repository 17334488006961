import Validator from '../Validator'

const combineValidators = (validators: Validator[]): Validator => (
  originalString,
  translatedString
) => {
  for (const validator of validators) {
    const result = validator(originalString, translatedString)
    if (!result.isValid) {
      return result
    }
  }
  return { isValid: true }
}

export default combineValidators
