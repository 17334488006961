import State from '../../../redux/modules/State'
import OwnProps from './props/OwnProps'
import TranslationStatus from '../../../redux/modules/filter/TranslationStatus'
import {
  getCurrentFilter,
  getCurrentPlatform,
  getFilterFromLocation,
  getPlatformFromMatch,
} from '../../../util/route/filterSelector'
import { createSelector } from 'reselect'
import {
  completedTranslationsFromLanguage,
  getPlatformStateSet,
  openTranslationsFromLanguage,
  platformTranslationsFromPlatform,
} from '../../../redux/modules/translations/selectors'
import { getUserLanguage } from '../../../redux/modules/user/selectors'
import TranslatableString from '../../../api/model/TranslatableString'
import TranslationsState from '../../../redux/modules/translations/TranslationsState'
import { RouteComponentProps } from 'react-router'
import TranslationRouteProps from './props/TranslationRouteProps'

const getTranslationId = (
  state: State,
  ownProps: RouteComponentProps<TranslationRouteProps>
): string => {
  return ownProps.match.params.translationId
}

const getPreviousFilter = (
  state: State,
  ownProps: OwnProps
): TranslationStatus => {
  if (ownProps.previousLocation !== undefined) {
    return getFilterFromLocation(ownProps.previousLocation)
  } else {
    return getCurrentFilter(state, ownProps)
  }
}

const getPreviousProject = (state: State, ownProps: OwnProps): string => {
  if (ownProps.previousMatch) {
    return getPlatformFromMatch(ownProps.previousMatch)
  } else {
    return getCurrentPlatform(state, ownProps)
  }
}

const getPreviousTranslations = createSelector(
  getPlatformStateSet,
  getPreviousProject,
  (translations, project) => {
    return platformTranslationsFromPlatform(translations, project)
  }
)

const getOpenTranslations = createSelector(
  getPreviousTranslations,
  getUserLanguage,
  openTranslationsFromLanguage
)

const getCompletedTranslations = createSelector(
  getPreviousTranslations,
  getUserLanguage,
  completedTranslationsFromLanguage
)

const getPreviousFilteredTranslations = createSelector(
  getOpenTranslations,
  getCompletedTranslations,
  getPreviousFilter,
  (openTranslations, completedTranslations, filter) => {
    switch (filter) {
      case TranslationStatus.OPEN:
        return openTranslations.sort(
          (prevString, currentString) =>
            prevString.submitted - currentString.submitted
        )
      case TranslationStatus.COMPLETED:
        return completedTranslations.sort(
          (prevString, currentString) =>
            currentString.submitted - prevString.submitted
        )
    }
  }
)

const indexOfCurrentTranslation = createSelector(
  getTranslationId,
  getPreviousFilteredTranslations,
  (translationId: string, platformTranslations: TranslatableString[]): number =>
    platformTranslations.findIndex((it) => it.id === translationId)
)

const getTranslation = createSelector(
  getPlatformStateSet,
  getCurrentPlatform,
  getTranslationId,
  (
    translations: TranslationsState,
    translationPlatform: string,
    translationId: string
  ) => {
    const platform = translations[translationPlatform]
    if (platform) {
      return platform[translationId]
    } else {
      return undefined
    }
  }
)

const getNextTranslation = createSelector(
  getPreviousFilteredTranslations,
  indexOfCurrentTranslation,
  (translations: TranslatableString[], index: number) =>
    index >= 0 && index <= translations.length - 1
      ? translations[index + 1]
      : undefined
)

const isNextOnSubmit = createSelector(
  getPreviousFilter,
  (filter: TranslationStatus) => filter === TranslationStatus.OPEN
)

export {
  getTranslation,
  isNextOnSubmit,
  getNextTranslation,
  getPreviousFilter,
  getPreviousProject,
}
