import React, { useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import TranslationTable from '../../components/TranslationTable/TranslationTable'
import Props from './props/Props'
import TranslationsAppHeader from '../../components/Header/TranslationsAppHeader'
import TranslationsFilter from './Filter/TranslationsFilter'
import SearchEmpty from './SearchEmpty/SearchEmpty'
import Loading from '../../components/Loading/Loading'

const SearchResults: React.FunctionComponent<Props> = (props: Props) => {
  // When the query prop changes push an action through to update the search results
  useEffect(() => {
    props.onQueryChanged(props.query || '')
  }, [props.query])

  return (
    <div>
      <TranslationsAppHeader {...props} />
      <Container fluid={true}>
        <Row>
          <Col className="justify-content-md-center mb-5">
            <h3 className="mt-5 mb-4">Search Results: {props.query}</h3>
            <TranslationsFilter {...props} />
            {props.isLoading && <Loading />}
            {!props.isLoading && props.translations.length <= 0 && (
              <SearchEmpty />
            )}
            {!props.isLoading && props.translations.length > 0 && (
              <TranslationTable {...props} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SearchResults
