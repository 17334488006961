import { connect } from 'react-redux'
import State from '../../redux/modules/State'
import { RouteComponentProps, withRouter } from 'react-router'
import StateProps from './props/StateProps'
import AuthenticationStatus from '../../api/model/AuthenticationStatus'
import { initiateLogin } from '../../redux/modules/user/actions'
import DispatchProps from './props/DispatchProps'
import Login from './Login'

const mapStateToProps = (state: State): StateProps => {
  return {
    isAuthenticated:
      state.userState.status === AuthenticationStatus.AUTHENTICATED,
  }
}

const mapDispatchToProps: DispatchProps = {
  login: initiateLogin,
}

export default withRouter(
  connect<StateProps, DispatchProps, RouteComponentProps, State>(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
)
