import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import React from 'react'
import Props from './Props'
import TranslationStatus from '../../redux/modules/filter/TranslationStatus'
import StatusFilterLink from '../StatusFilterLink/StatusFilterLink'

const StatusFilter = (props: Props) => {
  return (
    <ButtonGroup className="mr-2 mb-2" aria-label="Translations Filter">
      <StatusFilterLink filter={TranslationStatus.OPEN} {...props}>
        <Button
          active={props.statusFilter === TranslationStatus.OPEN}
          variant={
            props.statusFilter === status ? 'secondary' : 'outline-secondary'
          }
        >
          {`${props.openCount} Open`}
        </Button>
      </StatusFilterLink>
      <StatusFilterLink filter={TranslationStatus.COMPLETED} {...props}>
        <Button
          active={props.statusFilter === TranslationStatus.COMPLETED}
          variant={
            props.statusFilter === status ? 'secondary' : 'outline-secondary'
          }
        >
          Completed
        </Button>
      </StatusFilterLink>
    </ButtonGroup>
  )
}

export default StatusFilter
