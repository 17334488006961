import PlatformAction from './PlatformAction'

const subscribe = (): PlatformAction => ({
  type: 'SUBSCRIBE',
})

const unsubscribe = (): PlatformAction => ({
  type: 'UNSUBSCRIBE',
})

const subscribeToPlatform = (platform: string): PlatformAction => ({
  type: 'SUBSCRIBE_TO_PLATFORM',
  platform: platform,
})

const unsubscribeFromPlatform = (platform: string): PlatformAction => ({
  type: 'UNSUBSCRIBE_FROM_PLATFORM',
  platform: platform,
})

const onPlatformsChanged = (platforms: string[]): PlatformAction => ({
  type: 'ON_PLATFORMS_CHANGED',
  platforms: platforms,
})

export {
  subscribe,
  unsubscribe,
  subscribeToPlatform,
  unsubscribeFromPlatform,
  onPlatformsChanged,
}
