import TranslationsState from './TranslationsState'
import TranslationAction from './TranslationAction'
import TranslationMap from './TranslationMap'
import { Reducer } from 'redux'

const initialState: TranslationMap = {}
const platformReducer = (
  state: TranslationMap = initialState,
  action: TranslationAction
): TranslationMap => {
  switch (action.type) {
    case 'ON_TRANSLATIONS_CHANGED':
      return action.strings.reduce(
        (result, it) => ({
          ...result,
          [it.id]: it,
        }),
        state
      )
    default:
      return state
  }
}

const initialPlatformState: TranslationsState = {}
const platformSetReducer: Reducer<TranslationsState, TranslationAction> = (
  state: TranslationsState = initialPlatformState,
  action: TranslationAction
) => {
  switch (action.type) {
    case 'ON_TRANSLATIONS_CHANGED':
      return {
        ...state,
        [action.platform]: platformReducer(state[action.platform], action),
      }
    default:
      return state
  }
}

export default platformSetReducer
