import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

const NotFound: React.FunctionComponent = () => {
  return (
    <Container fluid>
      <Row className="mt-2">
        <Col>
          <h2>404 - Page Not Found</h2>
        </Col>
      </Row>
    </Container>
  )
}

export default NotFound
