import SearchAction from './SearchAction'

const onQueryChanged = (query: string): SearchAction => ({
  type: 'ON_QUERY_CHANGED',
  query,
})

const onSearchResults = (translationIds: [string, string][]): SearchAction => ({
  type: 'ON_SEARCH_RESULTS_RESOLVED',
  translationIds,
})

export { onQueryChanged, onSearchResults }
