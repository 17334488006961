import {
  call,
  cancelled,
  fork,
  put,
  race,
  take,
  takeEvery,
} from 'redux-saga/effects'
import {
  onStringUpdateFailure,
  onStringUpdateSuccess,
  onTranslationChanged,
} from './actions'
import {
  subscribeToPlatforms,
  subscribeToTranslations,
  updateTranslationString,
} from '../../../api/FirestoreService'
import AuthenticationStatus from '../../../api/model/AuthenticationStatus'
import { onPlatformsChanged, subscribeToPlatform } from '../platforms/actions'

/**
 * Emits ON_TRANSLATIONS_CHANGED until cancelled.
 */
function* watchTranslations(platform: string) {
  const channel = yield call(subscribeToTranslations, platform)
  try {
    while (true) {
      const translations = yield take(channel)
      yield put(onTranslationChanged(platform, translations))
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }
  }
}

/**
 * Cancellable version of watchTranslations.
 *
 * Cancelled by UNSUBSCRIBE_FROM_PLATFORM where the platform is the provided platform,
 * or by an UNSUBSCRIBE action.
 */
function* cancellableWatchTranslations(platform: string) {
  yield race({
    // starts the task in the background
    task: call(watchTranslations, platform),
    // cancellation will propagate downwards
    // Only cancel the race when we get another unsubscribe that is for this platform
    cancel: take(
      (action: any) =>
        (action.type === 'UNSUBSCRIBE_FROM_PLATFORM' &&
          action.platform === platform) ||
        action.type === 'UNSUBSCRIBE'
    ),
  })
}

/**
 * When a SUBSCRIBE_TO_PLATFORM action occurs, begin watching that platform for changes.
 * This will trigger ON_TRANSLATIONS_CHANGED actions whenever the translations
 * change under the platform provided.
 *
 * Workflow is:
 * SUBSCRIBE_TO_PLATFORM -> ON_TRANSLATIONS_CHANGED -> UNSUBSCRIBE_FROM_PLATFORM || END
 */
function* watchTranslationsInBackground() {
  while (true) {
    // Effectively takeEvery, but lets us cancel when UNSUBSCRIBE_FROM_PLATFORM is called
    const { platform } = yield take('SUBSCRIBE_TO_PLATFORM')
    yield fork(cancellableWatchTranslations, platform)
  }
}

/**
 * Emits ON_PLATFORMS_CHANGED until cancelled.
 */
function* watchPlatforms() {
  const channel = yield call(subscribeToPlatforms)
  try {
    while (true) {
      const platforms = yield take(channel)
      yield put(onPlatformsChanged(platforms))
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }
  }
}

/**
 * Abstraction over watchPlatforms that allows cancellation through the
 * UNSUBSCRIBE action.
 *
 * Dispatching an UNSUBSCRIBE action will cause the platform list to stop being watched.
 *
 * SUBSCRIBE -> ON_PLATFORMS_CHANGED -> UNSUBSCRIBE || END
 */
function* cancellableWatchPlatforms() {
  yield race({
    // starts the task in the background
    task: call(watchPlatforms),
    // cancellation will propagate downwards
    // Only cancel the race when we get another unsubscribe that is for this platform
    cancel: take('UNSUBSCRIBE'),
  })
}

/**
 * When a SUBSCRIBE action occurs, begin watching the list of platforms.
 * This will trigger ON_PLATFORMS_CHANGED actions which will then trigger subscriptions
 * to each of the platforms.
 *
 * The workflow is:
 * SUBSCRIBE -> ON_PLATFORMS_CHANGED -> SUBSCRIBE_TO_PLATFORM -> UNSUBSCRIBE || END
 */
function* watchPlatformsInBackground() {
  yield takeEvery(
    (action: any) =>
      (action.type === 'ON_AUTHENTICATION_STATUS_CHANGED' &&
        action.status === AuthenticationStatus.AUTHENTICATED) ||
      action.type === 'SUBSCRIBE',
    cancellableWatchPlatforms
  )
}

/**
 * When the platforms change (ON_PLATFORM_CHANGED) subscribe to each of the
 * platforms.
 */
function* autoSubscribeToPlatforms() {
  while (true) {
    const { platforms } = yield take('ON_PLATFORMS_CHANGED')
    // Ideally, we would only subscribe to the new platforms/unsubscribe from removed platforms
    // Not sure how to do that though...
    for (const platformId of platforms) {
      yield put(subscribeToPlatform(platformId))
    }
  }
}

function* performTranslationUpdate(
  project: string,
  translationId: string,
  languageCode: string,
  newString: string,
  flagged: boolean,
) {
  const success = yield call(
    updateTranslationString,
    project,
    translationId,
    languageCode,
    newString,
    flagged
  )
  if (success) {
    yield put(
      onStringUpdateSuccess(project, translationId, languageCode, newString, flagged)
    )
  } else {
    yield put(
      onStringUpdateFailure(project, translationId, languageCode, newString, flagged)
    )
  }
}

function* watchTranslationUpdates() {
  while (true) {
    const {
      project,
      translationId,
      languageCode,
      translatedString,
      flagged,
    } = yield take('ON_STRING_UPDATED')
    yield performTranslationUpdate(
      project,
      translationId,
      languageCode,
      translatedString,
      flagged,
    )
  }
}

export {
  watchTranslationsInBackground,
  watchPlatformsInBackground,
  autoSubscribeToPlatforms,
  watchTranslationUpdates,
}
