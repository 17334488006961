import Dropdown from 'react-bootstrap/Dropdown'
import React from 'react'
import Props from './Props'
import ProjectLink from '../ProjectLink/ProjectLink'

const ProjectFilter = (props: Props) => {
  return (
    <Dropdown className="mr-2  mb-2">
      <Dropdown.Toggle variant="secondary" id="project-dropdown">
        {`Project (${props.projectFilter ? props.projectFilter : 'All'})`}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <ProjectLink key={'all'} projectId="" {...props}>
          <Dropdown.Item>All</Dropdown.Item>
        </ProjectLink>
        {props.projectList.map((projectId) => (
          <ProjectLink key={projectId} projectId={projectId} {...props}>
            <Dropdown.Item>{projectId}</Dropdown.Item>
          </ProjectLink>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProjectFilter
