import TranslatableString from '../../../api/model/TranslatableString'
import TranslationAction from './TranslationAction'

const onStringUpdated = (
  project: string,
  translationId: string,
  languageCode: string,
  translatedString: string,
  flagged: boolean
): TranslationAction => ({
  type: 'ON_STRING_UPDATED',
  translationId,
  project,
  languageCode,
  translatedString,
  flagged
})

const onStringUpdateSuccess = (
  project: string,
  translationId: string,
  languageCode: string,
  translatedString: string,
  flagged: boolean
): TranslationAction => ({
  type: 'ON_STRING_UPDATE_SUCCESS',
  translationId,
  project,
  languageCode,
  translatedString,
  flagged,
})

const onStringUpdateFailure = (
  project: string,
  translationId: string,
  languageCode: string,
  translatedString: string,
  flagged: boolean
): TranslationAction => ({
  type: 'ON_STRING_UPDATE_FAILURE',
  translationId,
  project,
  languageCode,
  translatedString,
  flagged,
})

const onTranslationChanged = (
  platform: string,
  strings: TranslatableString[]
): TranslationAction => ({
  type: 'ON_TRANSLATIONS_CHANGED',
  platform: platform,
  strings: strings,
})

export {
  onTranslationChanged,
  onStringUpdated,
  onStringUpdateSuccess,
  onStringUpdateFailure,
}
