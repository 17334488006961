import { LinkContainer } from 'react-router-bootstrap'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import queryString from 'query-string'

interface PageProps extends RouteComponentProps {
  page: number
  children: any
}

const PageLink = (props: PageProps) => {
  const queryParams = queryString.parse(props.location.search)
  const currentPageString = (queryParams.page as string) || '0'
  const currentPage = parseInt(currentPageString)
  const newParams = {
    ...queryParams,
    page: props.page,
  }

  const link = {
    pathname: props.location.pathname,
    search: queryString.stringify(newParams),
  }
  return (
    <LinkContainer to={link} isActive={() => currentPage === props.page}>
      {props.children}
    </LinkContainer>
  )
}

export default PageLink
