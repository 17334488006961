import ValidationResult from '../../ValidationResult'
import Validator from '../../Validator'

const trailingWhitespaceRegex = new RegExp('\\s+$')

const trailingWhitespace: Validator = (
  originalString: string,
  translatedString: string
): ValidationResult => {
  const originalMatches = originalString.match(trailingWhitespaceRegex)
  const translatedMatches = translatedString.match(trailingWhitespaceRegex)

  const originalHasWhitespace = originalMatches && originalMatches.length > 0
  const translatedHasWhitespace =
    translatedMatches && translatedMatches.length > 0

  if (originalHasWhitespace) {
    if (translatedHasWhitespace) {
      if (originalMatches!![0] !== translatedMatches!![0]) {
        return {
          isValid: false,
          errorMessage: 'Trailing whitespace must match',
        }
      }
    } else {
      return {
        isValid: false,
        errorMessage: 'Missing required trailing space',
      }
    }
  } else {
    if (translatedHasWhitespace) {
      return {
        isValid: false,
        errorMessage: 'No trailing space',
      }
    }
  }

  return { isValid: true }
}

export default trailingWhitespace
