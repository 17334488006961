import FormControl from 'react-bootstrap/FormControl'
import React, { KeyboardEvent } from 'react'
import Props from './Props'

const SearchInput = (props: Props) => {
  return (
    <FormControl
      id={'translation_search.input'}
      type="text"
      value={props.query}
      onChange={(event: any) => props.onQueryChanged(event.target.value)}
      placeholder="Search translations"
      aria-label="Search translations"
      aria-describedby="btnSearchLabel"
      onKeyPress={(event: KeyboardEvent) => {
        // Keydown because we don't want to accidentally put new lines
        // in the translation.
        if (
          event.key === 'Enter' &&
          document.activeElement!!.id === 'translation_search.input'
        ) {
          event.preventDefault()
          event.stopPropagation()
          props.onSubmitSearch()
        }
      }}
    />
  )
}

export default SearchInput
