import firebase from './Rome2rioFirebase'
import { buffers, END, EventChannel, eventChannel } from 'redux-saga'
import TranslatableString from './model/TranslatableString'
import Platforms from './model/Platforms'
import User from './model/User'
import moment from 'moment'

function subscribeToTranslations(
  platform: string
): EventChannel<TranslatableString[]> {
  return eventChannel<TranslatableString[]>((emitter) => {
    // onSnapshot returns an unsubscribe function
    return firebase.firestore!!()
      .collection('translations')
      .doc('platform')
      .collection(platform)
      .where('unused', '==', false)
      .onSnapshot(
        (snapshot) => {
          emitter(
            snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
                platform,
              } as TranslatableString
            })
          )
        },
        (error) => {
          console.log(error)
          emitter(END)
        },
        () => {
          emitter(END)
        }
      )
  }, buffers.sliding(1))
}

function subscribeToPlatforms(): EventChannel<string[]> {
  return eventChannel<string[]>((emitter) => {
    // onSnapshot returns an unsubscribe function
    return firebase.firestore!!()
      .collection('settings')
      .doc('platform')
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const platforms = doc.data() as Platforms
            emitter(platforms.activePlatforms)
          }
        },
        (error) => {
          console.log(error)
          emitter(END)
        },
        () => {
          emitter(END)
        }
      )
  }, buffers.sliding(1))
}

function subscribeToUser(email: string): EventChannel<User> {
  return eventChannel((emitter) => {
    return firebase.firestore!!()
      .collection('users')
      .doc(email)
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            emitter({
              email: doc.data().email,
              displayName: doc.data().displayName,
              languageCode: doc.data().userLanguage,
              userType: doc.data().userType,
              disabled: doc.data().disabled,
            })
          }
        },
        (error) => {
          console.log(error)
          emitter(END)
        },
        () => {
          emitter(END)
        }
      )
  })
}

async function updateTranslationString(
  project: string,
  translationId: string,
  languageCode: string,
  newString: string,
  flagged: boolean
): Promise<Boolean> {
  try {
    await firebase.firestore!!()
      .collection('translations')
      .doc('platform')
      .collection(project)
      .doc(translationId)
      .update({
        [`translations.${languageCode}.translated`]: true,
        [`translations.${languageCode}.flagged`]: flagged,
        [`translations.${languageCode}.translatedString`]: newString,
        [`translations.${languageCode}.lastEdit`]: moment().valueOf(),
      })
  } catch (ex) {
    console.error(ex)
    return false
  }
  return true
}

export {
  subscribeToTranslations,
  subscribeToPlatforms,
  subscribeToUser,
  updateTranslationString,
}
