import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import React from 'react'
import Props from './Props'
import ProjectFilter from '../../../../components/ProjectFilter/ProjectFilter'
import StatusFilter from '../../../../components/StatusFilter/StatusFilter'

const TranslationsFilter: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <ButtonToolbar
      className="mb-2 justify-content-start"
      aria-label="Toolbar with Button groups"
    >
      <StatusFilter {...props} />
      <ProjectFilter {...props} />
    </ButtonToolbar>
  )
}

export default TranslationsFilter
