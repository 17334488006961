import { variableValidator } from './common'

/**
 * Both Android and iOS use formats inspired by the printf standard for string replacements.
 *
 * The regex defined here doesn't cover the whole specification offered by both platforms, but it covers what we've used
 * so far.
 *
 * iOS: https://developer.apple.com/library/archive/documentation/Cocoa/Conceptual/Strings/Articles/formatSpecifiers.html
 * Android: https://developer.android.com/reference/java/util/Formatter
 */
const regExp = new RegExp('%\\d*\\$?[sdi@]', 'g')
export default variableValidator(regExp)
