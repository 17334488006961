import Props from './props/Props'
import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Figure from 'react-bootstrap/Figure'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import validator from '../../../util/validation'
import TranslationInput from './TranslationInput/TranslationInput'
import SimilarTranslations from './SimilarTranslations/SimilarTranslations.container'

interface State {
  translatedStringError?: string
  draftTranslation: string
  previousTranslationId: string
  previousTranslationPlatform: string,
  flagged: boolean,
}

class TranslationDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    // TODO: Re-implement this as hooks
    this.state = {
      draftTranslation:
        props.translation && props.languageCode
          ? props.translation.translations[props.languageCode].translatedString
          : '',
      previousTranslationId: props.translation ? props.translation.id : '',
      previousTranslationPlatform: props.translation
        ? props.translation.platform
        : '',
      flagged: props.translation && props.languageCode 
        ? props.translation.translations[props.languageCode].flagged
        : false
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    // Anytime the translation changes, reset the draft translation text.
    if (
      props.languageCode &&
      props.translation &&
      (props.translation.id !== state.previousTranslationId ||
        props.translation.platform !== state.previousTranslationPlatform)
    ) {
      return {
        previousTranslationId: props.translation.id,
        previousTranslationPlatform: props.translation.platform,
        draftTranslation:
          props.translation.translations[props.languageCode].translatedString,
      }
    }
    return null
  }

  handleTranslationChange = (translation: string) => {
    this.setState({ draftTranslation: translation })
  }

  handleClose = () => {
    if (!this.props.closeModal()) {
      // The user must have deeplinked in, so we construct an appropriate path for them to route to.
      const trimmedPath = this.props.location.pathname.slice(
        0,
        this.props.location.pathname.lastIndexOf('/')
      )
      this.props.history.push({
        pathname: trimmedPath,
        search: this.props.location.search,
        state: {
          modal: true,
        },
      })
    }
  }

  handleSubmit = () => {
    const validation = validator(
      this.props.translation!!.originalString,
      this.state.draftTranslation
    )
    if (validation.isValid) {
      const languageCode = this.props.languageCode!!
      const translation = this.props.translation!!
      this.props.submitTranslation(
        translation.platform,
        translation.id,
        languageCode,
        this.state.draftTranslation,
        this.state.flagged
      )

      if (this.props.nextTranslation && this.props.isNextOnSubmit) {
        this.props.history.push({
          pathname: `/translations/${this.props.nextTranslation.platform}/${
            this.props.nextTranslation.id
          }`,
          search: this.props.location.search,
          state: {
            modal: true,
          },
        })
      } else {
        this.handleClose()
      }
    } else {
      this.setState({ translatedStringError: validation.errorMessage })
    }
  }

  handleToggleFlagged = () => {
    this.setState({ flagged: !this.state.flagged }, this.handleSubmit)
  }

  loadingModalBody = () => (
    <Modal.Body>
      <div className="mt-5 mb-5 d-flex flex-column justify-content-center">
        <div className="spinner-border align-self-center mb-2" role="status">
          <span className="sr-only">Loading Translation</span>
        </div>
        <strong className="align-self-center">Loading Translation</strong>
      </div>
    </Modal.Body>
  )

  alerts = () => {
    const translation = this.props.translation
    if (!translation || !this.props.languageCode) {
      return null
    }
    if (!translation.translations[this.props.languageCode].flagged) {
      return null
    }

    return (
      <Alert variant="danger">
        This translation has been flagged for review, please ensure it is correct.
      </Alert>
    )
  }

  contextBody = () => {
    const translation = this.props.translation
    if (!translation) {
      return null
    }

    if (
      !translation.contextPictureUrl &&
      !translation.contextText &&
      !translation.contextText.trim()
    ) {
      return null
    }

    // Test routes
    // Has an image: http://localhost:3000/translations/react/CartBook.proceedInbound
    // Has text: http://localhost:3000/translations/android/sunday_short
    return (
      <div>
        <Figure className="mb-0">
          {translation.contextPictureUrl && (
            <a href={translation.contextPictureUrl} target="_blank">
              <Figure.Image
                fluid
                height={200}
                width={200}
                src={translation.contextPictureUrl}
              />
            </a>
          )}
          {translation.contextText && (
            <Figure.Caption>
              <b>Context:</b> {translation.contextText}
            </Figure.Caption>
          )}
        </Figure>
        <hr />
      </div>
    )
  }

  contentModalBody = () => {
    const translation = this.props.translation
    if (!translation) {
      return null
    }
    const hasContext = translation.contextText || translation.contextPictureUrl
    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Translation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={() => this.handleSubmit}>
            {this.alerts()}
            {hasContext && this.contextBody()}
            <Form.Group controlId="edit_translation.originalString">
              <Form.Label>Original Text</Form.Label>
              <Form.Control
                readOnly
                as="textarea"
                rows="3"
                data-enable-grammarly="false"
                value={translation.originalString}
              />
            </Form.Group>

            <TranslationInput
              validationErrorMessage={this.state.translatedStringError}
              currentTranslation={this.state.draftTranslation}
              onUpdate={this.handleTranslationChange}
              onSubmit={this.handleSubmit}
              isFocused={true}
            />
          </Form>

          <SimilarTranslations
            originalString={translation.originalString}
            {...this.props}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={this.handleClose}>
            Discard Changes
          </Button>
          <Button type="button" onClick={this.handleToggleFlagged}>
            {this.state.flagged ? 'Confirm Translation' : 'Requires Review'}
          </Button>
          <Button type="button" onClick={this.handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </div>
    )
  }

  render() {
    let body
    if (this.props.isLoading) {
      body = this.loadingModalBody()
    } else {
      body = this.contentModalBody()
    }
    return (
      <Modal
        backdrop="static"
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={this.handleClose}
      >
        {body}
      </Modal>
    )
  }
}

export default TranslationDetail
