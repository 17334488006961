import Pagination from 'react-bootstrap/Pagination'
import React from 'react'
import Props from './Props'
import clamp from '../clamp'
import PageLink from './PageLink'

const PageIndicator: React.FunctionComponent<Props> = (props: Props) => {
  // Don't render a page indicator if there aren't any pages
  if (props.pageCount === 1) return null

  // Magic numbers, we want to show 6 pages
  let lowPage = props.currentPage - 3
  let highPage = props.currentPage + 3
  if (lowPage < 0) {
    // If the low page is below 0, push the high page up the difference
    highPage = highPage + -lowPage
  }

  if (highPage > props.pageCount) {
    // If the highPage is above the max page count, push the lowPage down
    // the difference between high and pageCount.
    lowPage = lowPage + (highPage - props.pageCount)
  }
  const clampedLowPageIndex = clamp(lowPage, 0, props.pageCount)
  const clampedHighPageIndex = clamp(highPage, 0, props.pageCount)

  const shownPageCount = clampedHighPageIndex - clampedLowPageIndex
  const isMorePages = props.pageCount > shownPageCount + 1
  const pagesIndexes = Array(shownPageCount)
    .fill(0)
    .map((value, index) => clampedLowPageIndex + index)

  return (
    <Pagination>
      {isMorePages && (
        <PageLink page={0} {...props} key="first">
          <Pagination.First tabIndex={0} disabled={props.currentPage === 0} />
        </PageLink>
      )}
      <PageLink page={props.currentPage - 1} {...props} key="previous">
        <Pagination.Prev tabIndex={0} disabled={props.currentPage === 0} />
      </PageLink>
      {pagesIndexes.map((value) => (
        <PageLink page={value} {...props} key={value}>
          <Pagination.Item tabIndex={0}>{value + 1}</Pagination.Item>
        </PageLink>
      ))}

      {isMorePages && <Pagination.Ellipsis tabIndex={0} key="ellipsis" />}
      <PageLink page={props.currentPage + 1} {...props} key="next">
        <Pagination.Next
          tabIndex={0}
          disabled={props.currentPage === props.pageCount - 1}
        />
      </PageLink>
      {isMorePages && (
        <PageLink page={props.pageCount - 1} {...props} key="last">
          <Pagination.Last
            tabIndex={0}
            disabled={props.currentPage === props.pageCount - 1}
          />
        </PageLink>
      )}
    </Pagination>
  )
}

export default PageIndicator
