import PlatformsState from './PlatformsState'
import PlatformAction from './PlatformAction'
import Platform from './Platform'
import TranslationAction from '../translations/TranslationAction'
import PlatformMap from './PlatformMap'
import { Reducer } from 'redux'

const initialPlatformState: Platform = {
  title: '',
  isLoading: true,
}
const platformReducer = (
  state: Platform = initialPlatformState,
  action: PlatformAction | TranslationAction
): Platform => {
  switch (action.type) {
    case 'SUBSCRIBE_TO_PLATFORM':
      return { ...state, isLoading: true, title: action.platform }
    case 'ON_TRANSLATIONS_CHANGED':
      return { ...state, isLoading: false }
    default:
      return state
  }
}

const platformMapReducer = (
  state: PlatformMap = {},
  action: PlatformAction | TranslationAction
): PlatformMap => {
  switch (action.type) {
    case 'ON_PLATFORMS_CHANGED':
      return {
        ...state,
        ...action.platforms.reduce(
          (prev: PlatformMap, current: string) => ({
            ...prev,
            [current]: platformReducer(state[current], action),
          }),
          {}
        ),
      }
    case 'ON_TRANSLATIONS_CHANGED':
      return {
        ...state,
        [action.platform]: platformReducer(state[action.platform], action),
      }
    default:
      return state
  }
}

const initialState: PlatformsState = {
  platformIds: [],
  platforms: {},
}

const platformsReducer: Reducer<
  PlatformsState,
  PlatformAction | TranslationAction
> = (
  state: PlatformsState = initialState,
  action: PlatformAction | TranslationAction
): PlatformsState => {
  switch (action.type) {
    case 'ON_PLATFORMS_CHANGED':
      return {
        ...state,
        platformIds: [...state.platformIds, ...action.platforms],
        platforms: platformMapReducer(state.platforms, action),
      }
    case 'SUBSCRIBE_TO_PLATFORM':
      return {
        ...state,
        platforms: {
          ...state.platforms,
          [action.platform]: platformReducer(
            state.platforms[action.platform],
            action
          ),
        },
      }
    case 'ON_TRANSLATIONS_CHANGED':
      return {
        ...state,
        platforms: platformMapReducer(state.platforms, action),
      }
    default:
      return state
  }
}

export default platformsReducer
