import React from 'react'
import emptyBanner from './emptyBanner.png'
import Image from 'react-bootstrap/Image'

const TranslationsEmpty = () => {
  return (
    <div className="mt-5 d-flex flex-column justify-content-center">
      <Image
        src={emptyBanner}
        width={200}
        className="mb-2 mt-2 mr-auto ml-auto"
      />
      <strong className="align-self-center text-muted">
        Woohoo! All open translations have been completed.
      </strong>
    </div>
  )
}

export default TranslationsEmpty
