import firebase from './Rome2rioFirebase'
import firebaseAuth from 'firebase'
import { buffers, EventChannel, eventChannel } from 'redux-saga'
import AuthenticationStatus from './model/AuthenticationStatus'
import AuthenticationState from './model/AuthenticationState'

function subscribeToAuthenticationStatus(): EventChannel<AuthenticationState> {
  return eventChannel<AuthenticationState>((emitter) => {
    // onSnapshot returns an unsubscribe function
    return firebase.auth!!().onAuthStateChanged((user) => {
      if (user) {
        emitter({
          email: user.email,
          status: AuthenticationStatus.AUTHENTICATED,
        })
      } else {
        emitter({
          email: null,
          status: AuthenticationStatus.NOT_AUTHENTICATED,
        })
      }
    })
  }, buffers.sliding(1))
}

async function tryLogin(): Promise<boolean> {
  const googleAuthProvider = new firebaseAuth.auth!!.GoogleAuthProvider()
  try {
    await firebase.auth!!().signInWithPopup(googleAuthProvider)
    return true
  } catch (error) {
    return false
  }
}

async function tryLogout(): Promise<boolean> {
  try {
    await firebase.auth!!().signOut()
    return true
  } catch (error) {
    return false
  }
}

export { tryLogin, tryLogout, subscribeToAuthenticationStatus }
