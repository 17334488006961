import * as React from 'react'
import Form from 'react-bootstrap/Form'
import TranslationInputProps from './TranslationInputProps'
import { useEffect, useRef } from 'react'

const TranslationInput: React.FunctionComponent<TranslationInputProps> = (
  props: TranslationInputProps
) => {
  const inputRef = useRef<any>()

  useEffect(() => {
    if (props.isFocused && !inputRef.current.isFocused) {
      inputRef.current.focus()
    }
  })

  return (
    <Form.Group controlId="edit_translation.originalString">
      <Form.Label>Translated Text</Form.Label>
      <Form.Control
        as="textarea"
        rows="3"
        ref={inputRef}
        value={props.currentTranslation}
        onChange={(value: any) => props.onUpdate(value.target.value)}
        autoFocus
        isInvalid={!!props.validationErrorMessage}
        data-enable-grammarly="false"
        onKeyDown={(event: KeyboardEvent) => {
          // Keydown because we don't want to accidentally put new lines
          // in the translation.
          if (
            event.key === 'Enter' &&
            !event.getModifierState('Shift') &&
            document.activeElement!!.id === 'edit_translation.originalString'
          ) {
            // Enter + Shift does a newline, Enter by itself will submit as long as the textarea is focused.
            event.preventDefault()
            event.stopPropagation()
            props.onSubmit()
          }
        }}
      />
      <Form.Control.Feedback type="invalid">
        {props.validationErrorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default TranslationInput
