/**
 * Returns value if it is between (or equal to) min and max.
 * Otherwise, it returns max if value to higher than max and min if the
 * value is lower than min.
 *
 * @param value Value to clamp between min and max
 * @param min Min number that value should be clamped to.
 * @param max Max number that value should be clamped to.
 */
const clamp = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max)
}

export default clamp
