import State from '../../redux/modules/State'
import { RouteComponentProps } from 'react-router'
import OptionalProjectRouteProps from '../../util/route/OptionalProjectRouteProps'
import {
  getCurrentPage,
  getCurrentPlatform,
  getSearchQuery,
} from '../../util/route/filterSelector'
import { getPlatformIsLoading } from '../../redux/modules/platforms/selectors'
import {
  getIsDeveloper,
  getUserLanguage,
  getUserName,
} from '../../redux/modules/user/selectors'
import { connect } from 'react-redux'
import { onQueryChanged } from '../../redux/modules/search/actions'
import DispatchProps from './props/DispatchProps'
import { updateUserLanguage } from '../../redux/modules/user/actions'
import StateProps from './props/StateProps'
import SearchResults from './SearchResults'
import { getSearchResultsTranslations } from '../../redux/modules/search/selectors'

const mapStateToProps = (
  state: State,
  ownProps: RouteComponentProps<OptionalProjectRouteProps>
): StateProps => {
  return {
    projectList: state.platformsState.platformIds,
    projectFilter: getCurrentPlatform(state, ownProps),
    translations: getSearchResultsTranslations(state, ownProps),
    isLoading: getPlatformIsLoading(state, ownProps),
    currentPage: getCurrentPage(state, ownProps),
    username: getUserName(state),
    languageCode: getUserLanguage(state),
    query: getSearchQuery(state, ownProps),
    isDeveloper: getIsDeveloper(state),
  }
}

const mapDispatchToProps: DispatchProps = {
  onQueryChanged,
  updateLanguage: updateUserLanguage,
}

export default connect<
  StateProps,
  DispatchProps,
  RouteComponentProps<OptionalProjectRouteProps>,
  State
>(
  mapStateToProps,
  mapDispatchToProps
)(SearchResults)
