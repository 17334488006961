import User from '../../../api/model/User'
import AuthenticationState from '../../../api/model/AuthenticationState'
import UserAction from './UserAction'

const onAuthenticationStatusChanged = (
  authenticationStatus: AuthenticationState
): UserAction => ({
  type: 'ON_AUTHENTICATION_STATUS_CHANGED',
  status: authenticationStatus.status,
  email: authenticationStatus.email,
})

const onUserChanged = (user: User): UserAction => ({
  type: 'ON_USER_CHANGED',
  user: user,
})

const updateUserLanguage = (languageCode: string): UserAction => ({
  type: 'UPDATE_LANGUAGE_CODE',
  languageCode,
})

const initiateLogin = (): UserAction => ({
  type: 'INIT_LOGIN',
})

const initiateLogout = (): UserAction => ({
  type: 'INIT_LOGOUT',
})

export {
  onUserChanged,
  onAuthenticationStatusChanged,
  updateUserLanguage,
  initiateLogin,
  initiateLogout,
}
