// Only import modules that we use
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// Initalize Firebase.
const config = {
  apiKey: 'AIzaSyBu9b89HkRuUSQEuW5QcUy3U2Sjo3mQdlE',
  authDomain: 'r2rtranslationtool.firebaseapp.com',
  databaseURL: 'https://r2rtranslationtool.firebaseio.com',
  projectId: 'r2rtranslationtool',
  storageBucket: 'r2rtranslationtool.appspot.com',
  messagingSenderId: '1002828084113',
}

const app = firebase.initializeApp(config)
const authProvider = new firebase.auth!!.GoogleAuthProvider()

export default app
export { authProvider }
