import React from 'react'

const Loading = () => {
  return (
    <div className="mt-5 d-flex flex-column justify-content-center">
      <div className="spinner-border align-self-center mb-2" role="status">
        <span className="sr-only">Loading Translations</span>
      </div>
      <strong className="align-self-center">Loading Translations</strong>
    </div>
  )
}

export default Loading
