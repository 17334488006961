import React from 'react'
import { match, matchPath, Redirect, Route, Switch } from 'react-router'
import TranslationList from '../Translations/List/TranslationList.container'
import NotFound from '../NotFound/NotFound'
import EditTranslation from '../Translations/Detail/TranslationDetail.container'
import Props from './props/Props'
import Login from '../Login/Login.container'
import { Location, LocationState } from 'history'
import OptionalProjectRouteProps from '../../util/route/OptionalProjectRouteProps'
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute'
import SearchResult from '../SearchResults/SearchResults.container'
import DisabledAccount from '../DisabledAccount/DisabledAccount.container'

class App extends React.Component<Props> {
  private previousLocation?: Location
  private previousMatch: match<OptionalProjectRouteProps> | null = null

  constructor(props: Props) {
    super(props)
  }

  componentWillUpdate(nextProps: Props) {
    const { location } = this.props
    const { state }: any = location
    // set previousLocation if props.location is not modal so the page in the background doesn't change
    if (
      nextProps.history.action !== 'POP' &&
      (!state || !state.modal) &&
      this.previousLocation !== location
    ) {
      this.previousLocation = this.props.location
      this.previousMatch = matchPath<OptionalProjectRouteProps>(
        this.props.location.pathname,
        {
          path: '/translations/:projectId?',
        }
      )
    }
  }

  routeToPreviousLocation = () => {
    if (this.previousLocation) {
      this.props.history.push(this.previousLocation)
      return true
    }
    return false
  }

  render() {
    const { location } = this.props
    const { state }: any = location
    const isModal = !!(
      state &&
      state.modal &&
      this.previousLocation !== location
    ) // not initial render

    if (this.props.isWaitingForAuthentication) {
      // TODO: Waiting for auth state
      return <div />
    } else {
      return (
        <div>
          <Switch
            location={
              isModal && this.previousLocation
                ? this.previousLocation
                : location
            }
          >
            <Redirect exact from="/" to="/translations" />
            <PrivateRoute
              path="/translations/:projectId?"
              component={TranslationList}
              isAuthenticated={this.props.isAuthenticated}
              isActive={this.props.isActive}
            />
            <PrivateRoute
              path="/search/:projectId?"
              component={SearchResult}
              isAuthenticated={this.props.isAuthenticated}
              isActive={this.props.isActive}
            />
            <Route path={'/login'} component={Login} />
            <Route path={'/inactive'} component={DisabledAccount} />
            <Route component={NotFound} />
          </Switch>
          <PrivateRoute
            path="/translations/:projectId/:translationId"
            isAuthenticated={this.props.isAuthenticated}
            isActive={this.props.isActive}
            render={(props: any) => (
              <EditTranslation
                {...props}
                closeModal={this.routeToPreviousLocation}
                previousLocation={this.previousLocation}
                previousMatch={this.previousMatch}
              />
            )}
          />
        </div>
      )
    }
  }
}

export default App
