import ValidationResult from '../../ValidationResult'
import Validator from '../../Validator'

const empty: Validator = (
  originalString: string,
  translatedString: string
): ValidationResult => {
  if (translatedString.trim().length < 1) {
    return { isValid: false, errorMessage: 'Translation is empty' }
  }

  return { isValid: true }
}

export default empty
