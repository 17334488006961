import { createSelector } from 'reselect'
import TranslationsState from './TranslationsState'
import {
  getCurrentFilter,
  getCurrentPlatform,
} from '../../../util/route/filterSelector'
import { getUserLanguage } from '../user/selectors'
import State from '../State'
import TranslationStatus from '../filter/TranslationStatus'
import TranslatableString from '../../../api/model/TranslatableString'
import TranslationMap from './TranslationMap'

const extractTranslations = (
  translationMap: TranslationMap
): TranslatableString[] =>
  Object.keys(translationMap).map((it) => translationMap[it])

const getPlatformStateSet = (state: State): TranslationsState =>
  state.translationsState

const getAllTranslations = (state: State): TranslatableString[] => {
  return Object.keys(state.translationsState)
    .map((it) => state.translationsState[it]!!)
    .flatMap((current: TranslationMap) => extractTranslations(current))
}

const platformTranslationsFromPlatform = (
  translationsState: TranslationsState,
  platform: string
): TranslatableString[] => {
  if (!platform || platform === 'all') {
    return Object.keys(translationsState)
      .map((it) => translationsState[it]!!)
      .flatMap((current: TranslationMap) => extractTranslations(current))
  } else {
    const current = translationsState[platform]
    return current ? extractTranslations(current) : []
  }
}

const openTranslationsFromLanguage = (
  platformStrings: TranslatableString[],
  userLanguage: string | undefined
) => {
  if (!userLanguage) {
    return []
  }
  return platformStrings.filter((translatable) => {
    const translation = translatable.translations[userLanguage]
    return translation.flagged || !translation.translated
  })
}

const completedTranslationsFromLanguage = (
  platformStrings: TranslatableString[],
  userLanguage: string | undefined
) => {
  if (!userLanguage) {
    return []
  }
  return platformStrings.filter((translatable) => {
    const translation = translatable.translations[userLanguage]
    return !translation.flagged && translation.translated
  })
}

const getPlatformTranslations = createSelector(
  getPlatformStateSet,
  getCurrentPlatform,
  (platformSetState, platform) =>
    platformTranslationsFromPlatform(platformSetState, platform)
)

const getOpenTranslations = createSelector(
  getPlatformTranslations,
  getUserLanguage,
  openTranslationsFromLanguage
)

const getCompletedTranslations = createSelector(
  getPlatformTranslations,
  getUserLanguage,
  completedTranslationsFromLanguage
)

const getOpenTranslationsCount = createSelector(
  getOpenTranslations,
  (translations) => translations.length
)

const getFilteredTranslations = createSelector(
  getOpenTranslations,
  getCompletedTranslations,
  getCurrentFilter,
  (openTranslations, completedTranslations, filter) => {
    switch (filter) {
      case TranslationStatus.OPEN:
        return openTranslations.sort(
          (prevString, currentString) =>
            prevString.submitted - currentString.submitted
        )
      case TranslationStatus.COMPLETED:
        return completedTranslations.sort(
          (prevString, currentString) =>
            currentString.submitted - prevString.submitted
        )
    }
  }
)

export {
  getPlatformStateSet,
  getPlatformTranslations,
  getFilteredTranslations,
  getOpenTranslationsCount,
  platformTranslationsFromPlatform,
  openTranslationsFromLanguage,
  completedTranslationsFromLanguage,
  getCompletedTranslations,
  getAllTranslations,
}
