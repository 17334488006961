import { spawn, put, fork } from 'redux-saga/effects'
import {
  autoSubscribeToPlatforms,
  watchPlatformsInBackground,
  watchTranslationsInBackground,
  watchTranslationUpdates,
} from './translations/sagas'
import {
  watchInitLogin,
  watchAuthenticationStatus,
  watchUserInBackground,
  watchInitLogout,
} from './user/sagas'
import { watchSearch, watchTranslationsChanged } from './search/sagas'

function* rootSaga() {
  // TODO: Automatic retry of sagas, currently, if one saga dies it'll never restart.
  yield spawn(watchTranslationsInBackground)
  yield spawn(watchPlatformsInBackground)
  yield spawn(autoSubscribeToPlatforms)
  yield spawn(watchAuthenticationStatus)
  yield spawn(watchUserInBackground)
  yield spawn(watchInitLogin)
  yield spawn(watchSearch)
  yield spawn(watchTranslationsChanged)
  yield spawn(watchTranslationUpdates)
  yield spawn(watchInitLogout)
}

export default rootSaga
