import { LinkContainer } from 'react-router-bootstrap'
import React from 'react'
import queryString from 'query-string'
import Props from './Props'

const StatusFilterLink = (props: Props) => {
  const params = queryString.parse(props.location.search)
  const newParams = {
    ...params,
    status: props.filter,
  }
  const link = {
    pathname: props.location.pathname,
    search: queryString.stringify(newParams),
  }
  return (
    <LinkContainer
      to={link}
      isActive={() => props.filter === params.status}
      replace={true}
    >
      {props.children}
    </LinkContainer>
  )
}

export default StatusFilterLink
