import { Redirect, Route } from 'react-router'
import React from 'react'
import Props from './Props'

const PrivateRoute = ({ component: Component, ...rest }: Props) => (
  <Route
    {...rest}
    render={(props) => {
      if (!rest.isAuthenticated) {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      } else if (!rest.isActive) {
        return (
          <Redirect
            to={{
              pathname: '/inactive',
              state: { from: props.location },
            }}
          />
        )
      }

      if (rest.render) {
        return rest.render(props)
      } else if (Component) {
        return <Component {...props} />
      } else {
        throw Error(
          'render function or Component must be provided to PrivateRoute'
        )
      }
    }}
  />
)

export default PrivateRoute
