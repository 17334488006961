import { LinkContainer } from 'react-router-bootstrap'
import React from 'react'
import Props from './Props'

const ProjectLink = (props: Props) => {
  const origin = props.location.pathname.split('/')[1]
  let link = `/${origin}/${props.projectId}${props.location.search}`
  if (!props.projectId) {
    // Special case for 'all'
    link = `/${origin}${props.location.search}`
  }
  return (
    <LinkContainer to={link} replace={true}>
      {props.children}
    </LinkContainer>
  )
}

export default ProjectLink
