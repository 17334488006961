import React from 'react'
import { Redirect } from 'react-router'
import Props from './props/Props'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

class Login extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const state: any = this.props.location!!.state
    const { from } = state || { from: { pathname: '/' } }

    if (this.props.isAuthenticated) {
      return <Redirect to={from} />
    }

    return (
      <Container fluid>
        <Row className="mt-2">
          <Col>
            <p>You must log in to view the page at {from.pathname}</p>
            <Button onClick={this.props.login}>Log in</Button>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Login
