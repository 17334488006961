import State from '../State'
import User from '../../../api/model/User'
import { createSelector } from 'reselect'
import AuthenticationStatus from '../../../api/model/AuthenticationStatus'

const getUser = (state: State): User | undefined => state.userState.user

const getUserLanguage = createSelector(
  getUser,
  (user): string | undefined => {
    if (user) {
      return user.languageCode
    } else {
      // What is a sane default?
      return undefined
    }
  }
)

const getUserName = createSelector(
  getUser,
  (user) => {
    if (user) {
      return user.displayName
    } else {
      // What is a sane default?
      return null
    }
  }
)

const getUserEmail = createSelector(
  getUser,
  (user) => {
    if (user) {
      return user.email
    } else {
      return null
    }
  }
)

const getIsDeveloper = createSelector(
  getUser,
  (user) => {
    if (user) {
      return user.userType === 'Developer'
    } else {
      return undefined
    }
  }
)

const getIsActive = createSelector(
  getUser,
  (user): boolean => {
    if (user) {
      return !user.disabled
    } else {
      return false
    }
  }
)

const getIsWaitingForAuthentication = (state: State) =>
  state.userState.status === AuthenticationStatus.WAITING ||
  (state.userState.status === AuthenticationStatus.AUTHENTICATED &&
    state.userState.user === undefined)

const getIsAuthenticated = (state: State) =>
  state.userState.status === AuthenticationStatus.AUTHENTICATED

export {
  getUserLanguage,
  getUserName,
  getIsDeveloper,
  getIsActive,
  getUserEmail,
  getIsWaitingForAuthentication,
  getIsAuthenticated,
}
