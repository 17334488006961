import { connect } from 'react-redux'
import State from '../../redux/modules/State'
import { RouteComponentProps, withRouter } from 'react-router'
import StateProps from './props/StateProps'
import { initiateLogout } from '../../redux/modules/user/actions'
import DispatchProps from './props/DispatchProps'
import { getIsActive, getUserEmail } from '../../redux/modules/user/selectors'
import DisabledAccount from './DisabledAccount'
import AuthenticationStatus from '../../api/model/AuthenticationStatus'

const mapStateToProps = (state: State): StateProps => {
  return {
    email: getUserEmail(state),
    isActive: getIsActive(state),
    isAuthenticated:
      state.userState.status === AuthenticationStatus.AUTHENTICATED,
  }
}

const mapDispatchToProps: DispatchProps = {
  logout: initiateLogout,
}

export default withRouter(
  connect<StateProps, DispatchProps, RouteComponentProps, State>(
    mapStateToProps,
    mapDispatchToProps
  )(DisabledAccount)
)
