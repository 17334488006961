import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import React, { useState } from 'react'
import Props from './Props'
import { LinkContainer } from 'react-router-bootstrap'
import SearchInput from '../SearchInput/SearchInput'
import queryString from 'query-string'

const TranslationsAppHeader: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [searchQuery, setSearchQuery] = useState(props.query || '')
  const onSubmit = () => {
    const search = queryString.stringify({
      q: searchQuery,
    })

    const route = {
      pathname: '/search',
      search,
    }

    props.history.push(route)
  }
  return (
    <Navbar bg="dark" variant="dark" expand="sm">
      <LinkContainer to="/translations">
        <Navbar.Brand>Translation Tool</Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="mr-auto">
          {/* Only show the language picker if the users language code is set */}
          {props.languageCode && (
            <NavDropdown
              title={`Language (${props.languageCode})`}
              id="nav-dropdown"
              disabled={!props.isDeveloper}
            >
              {/* TODO: Stop hardcoding languages */}
              <NavDropdown.Item
                onClick={() => props.updateLanguage('DE')}
                eventKey="DE"
              >
                German
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => props.updateLanguage('PT')}
                eventKey="PT"
              >
                Portuguese
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => props.updateLanguage('ES')}
                eventKey="ES"
              >
                Spanish
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => props.updateLanguage('FR')}
                eventKey="FR"
              >
                French
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => props.updateLanguage('IT')}
                eventKey="IT"
              >
                Italian
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => props.updateLanguage('RU')}
                eventKey="RU"
              >
                Russian
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>

        <Form inline onSubmit={onSubmit} className="mt-sm-0 mt-2">
          <SearchInput
            query={searchQuery}
            onQueryChanged={setSearchQuery}
            onSubmitSearch={onSubmit}
          />
          <Button
            className="ml-sm-2 mt-sm-0 mt-2"
            variant="outline-light"
            type="button"
            onClick={onSubmit}
          >
            Search
          </Button>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default TranslationsAppHeader
