import { put, select, take, takeLatest } from 'redux-saga/effects'
import { onSearchResults } from './actions'
import Fuse, { IFuseOptions } from 'fuse.js'
import TranslatableString from '../../../api/model/TranslatableString'
import { getAllTranslations } from '../translations/selectors'
import State from '../State'

// @ts-ignore we want to search translatedStrings which need to be explicitly keyed
const fuseOptions: IFuseOptions<TranslatableString> = {
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  minMatchCharLength: 3,
  keys: [
    'originalString',
    'location',
    'translations.DE.translatedString',
    'translations.FR.translatedString',
    'translations.PT.translatedString',
    'translations.IT.translatedString',
    'translations.ES.translatedString',
  ],
}

function* performSearch({ query }: any) {
  const allTranslations: TranslatableString[] = yield select(getAllTranslations)

  // fuse no longer matches all results on an empty query - return allTranslations to continue expected behavior
  const fuse = new Fuse(allTranslations, fuseOptions)
  const results =
    !query
      ? allTranslations.map<[string, string]>((it) => [it.platform, it.id])
      : fuse
        .search(query)
        .map<[string, string]>((it) => [it.item.platform, it.item.id])
  yield put(onSearchResults(results))
}

function* watchTranslationsChanged() {
  while (true) {
    yield take('ON_TRANSLATIONS_CHANGED')
    const query: string = yield select(
      (state: State) => state.searchState.query
    )
    if (query) {
      yield performSearch({ query })
    }
  }
}

function* watchSearch() {
  yield takeLatest('ON_QUERY_CHANGED', performSearch)
}

export { watchSearch, watchTranslationsChanged }
