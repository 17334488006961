import Validator from '../../Validator'

const variableValidator = (regExp: RegExp): Validator => (
  originalString,
  translatedString
) => {
  const missingVariables = findMissing(regExp, originalString, translatedString)
  const additionalVariables = findMissing(
    regExp,
    translatedString,
    originalString
  )

  if (missingVariables.length > 0) {
    const error = `Missing required variables: ${missingVariables.join(' ,')}`
    return {
      isValid: false,
      errorMessage: error,
    }
  } else if (additionalVariables.length > 0) {
    const error = `Unexpected variables: ${additionalVariables.join(' ,')}`
    return {
      isValid: false,
      errorMessage: error,
    }
  } else {
    return { isValid: true }
  }
}

const findMissing = (
  variableRegex: RegExp,
  originalString: string,
  translatedString: string
): string[] => {
  const requiredVariables = originalString.match(variableRegex)
  if (!requiredVariables) {
    return []
  }

  return requiredVariables.filter((variable) => {
    const originalVariablesCount = originalString.split(variable).length - 1
    const translatedVariablesCount = translatedString.split(variable).length - 1
    return originalVariablesCount !== translatedVariablesCount
  })
}

export { findMissing, variableValidator }
