import OwnProps from './props/OwnProps'
import Fuse, { IFuseOptions } from 'fuse.js'
import TranslatableString from '../../../../api/model/TranslatableString'
import { createSelector } from 'reselect'
import { getCompletedTranslations } from '../../../../redux/modules/translations/selectors'
import { uniqBy } from 'lodash'

const querySelector = (state: any, props: OwnProps) => {
  return props.originalString
}

const fuseOptions: IFuseOptions<TranslatableString> = {
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  minMatchCharLength: 3,
  keys: ['originalString'],
}

const getFuse = createSelector(
  getCompletedTranslations,
  (completed) => {
    return new Fuse(completed, fuseOptions)
  }
)

const makeSearchSelector = () =>
  createSelector(
    getFuse,
    querySelector,
    (fuse, query) => uniqBy(fuse.search(query).map(it => it.item), 'originalString').slice(0, 3)
  )

export { makeSearchSelector }
