import ValidationResult from '../../ValidationResult'
import Validator from '../../Validator'

const leadingWhitespaceRegex = new RegExp('^\\s+')

const leadingWhitespace: Validator = (
  originalString: string,
  translatedString: string
): ValidationResult => {
  const originalMatches = originalString.match(leadingWhitespaceRegex)
  const translatedMatches = translatedString.match(leadingWhitespaceRegex)

  const originalHasWhitespace = originalMatches && originalMatches.length > 0
  const translatedHasWhitespace =
    translatedMatches && translatedMatches.length > 0

  if (originalHasWhitespace) {
    if (translatedHasWhitespace) {
      if (originalMatches!![0] !== translatedMatches!![0]) {
        return { isValid: false, errorMessage: 'Leading whitespace must match' }
      }
    } else {
      return {
        isValid: false,
        errorMessage: 'Missing required leading space',
      }
    }
  } else {
    if (translatedHasWhitespace) {
      return {
        isValid: false,
        errorMessage: 'No leading space',
      }
    }
  }

  return { isValid: true }
}

export default leadingWhitespace
