import {
  subscribeToAuthenticationStatus,
  tryLogin,
  tryLogout,
} from '../../../api/FirebaseAuth'
import {
  call,
  cancelled,
  fork,
  put,
  race,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { onAuthenticationStatusChanged, onUserChanged } from './actions'
import AuthenticationState from '../../../api/model/AuthenticationState'
import { EventChannel } from 'redux-saga'
import { subscribeToUser } from '../../../api/FirestoreService'
import User from '../../../api/model/User'
import AuthenticationStatus from '../../../api/model/AuthenticationStatus'

function* watchAuthenticationStatus() {
  const channel: EventChannel<AuthenticationState> = yield call(
    subscribeToAuthenticationStatus
  )
  try {
    while (true) {
      const status: AuthenticationState = yield take(channel)
      yield put(onAuthenticationStatusChanged(status))
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }
  }
}

function* watchUser(email: string) {
  const channel: EventChannel<User> = yield call(subscribeToUser, email)
  try {
    while (true) {
      const user: User = yield take(channel)
      yield put(onUserChanged(user))
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }
  }
}

function* cancellableWatchUser(email: string) {
  yield race({
    // starts the task in the background
    task: call(watchUser, email),
    // cancellation will propagate downwards
    // Only cancel the race when we become unauthenticated
    cancel: take(
      (action: any) =>
        action.type === 'ON_AUTHENTICATION_STATUS_CHANGED' &&
        action.status == AuthenticationStatus.NOT_AUTHENTICATED
    ),
  })
}

/**
 * When a ON_AUTHENTICATION_STATUS_CHANGED action occurs, and it indicates
 * that we are authenticated, begin watching that user for changes.
 * This will trigger ON_USER_CHANGED actions whenever the currently
 * logged in users settings change.
 *
 * Workflow is:
 * ON_AUTHENTICATION_STATUS_CHANGED(AUTHENTICATED) -> ON_USER_CHANGED -> ON_AUTHENTICATION_STATUS_CHANGED(NOT_AUTHENTICATED) || END
 */
function* watchUserInBackground() {
  while (true) {
    // Effectively takeEvery, but lets us cancel when UNSUBSCRIBE_FROM_PLATFORM is called
    const { status, email } = yield take('ON_AUTHENTICATION_STATUS_CHANGED')
    if (status === AuthenticationStatus.AUTHENTICATED) {
      yield fork(cancellableWatchUser, email)
    }
  }
}

function* initLogin() {
  yield call(tryLogin)
}

function* watchInitLogin() {
  yield takeEvery('INIT_LOGIN', initLogin)
}

function* initLogout() {
  yield call(tryLogout)
}

function* watchInitLogout() {
  yield takeEvery('INIT_LOGOUT', initLogout)
}

export {
  watchAuthenticationStatus,
  watchUserInBackground,
  watchInitLogin,
  watchInitLogout,
}
