import UserState from './UserState'
import AuthenticationStatus from '../../../api/model/AuthenticationStatus'
import UserAction from './UserAction'

const initialUserState: UserState = {
  status: AuthenticationStatus.WAITING,
}

const userReducer = (
  state: UserState = initialUserState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case 'ON_USER_CHANGED':
      return {
        ...state,
        user: { ...state.user, ...action.user },
      }
    case 'ON_AUTHENTICATION_STATUS_CHANGED':
      return {
        ...state,
        status: action.status,
      }
    case 'UPDATE_LANGUAGE_CODE':
      return {
        ...state,
        user: { ...state.user!!, languageCode: action.languageCode },
      }
    default:
      return state
  }
}

export default userReducer
