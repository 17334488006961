import SearchState from './SearchState'
import SearchAction from './SearchAction'

const initialState: SearchState = {
  isLoading: false,
  query: '',
}

const reducer = (
  state: SearchState = initialState,
  action: SearchAction
): SearchState => {
  switch (action.type) {
    case 'ON_QUERY_CHANGED':
      return { ...state, query: action.query, isLoading: true }
    case 'ON_SEARCH_RESULTS_RESOLVED':
      return {
        ...state,
        searchResults: action.translationIds,
        isLoading: false,
      }
    default:
      return state
  }
}

export default reducer
