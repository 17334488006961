import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import loggerMiddleware from 'redux-logger'
import * as reducers from './modules'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootSaga from './modules/rootSaga'
import State from './modules/State'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [thunkMiddleware, loggerMiddleware, sagaMiddleware]

const rootReducer = combineReducers<State>(reducers)

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
)

sagaMiddleware.run(rootSaga)

export default store
