import Props from './Props'
import React from 'react'
import './style.css'
import TranslatableString from '../../api/model/TranslatableString'
import clamp from './clamp'
import Table from 'react-bootstrap/Table'
import TranslationTableRow from './Row/TranslationTableRow'
import PageIndicator from './PageIndicator/PageIndicator'

const translationsPerPage = 25

const makeOnSelectTranslation = (props: Props) => (
  projectId: string,
  translationId: string
): void => {
  props.history.push({
    pathname: `/translations/${projectId}/${translationId}`,
    state: { modal: true },
  })
}

const TranslationTable: React.FunctionComponent<Props> = (props: Props) => {
  const onSelectTranslation = makeOnSelectTranslation(props)
  // Calculate page count
  const pages: TranslatableString[][] = []
  // ceil because we want to round up to the nearest page, not down otherwise
  // we won't show all of the translations.
  const pageCount = Math.ceil(props.translations.length / translationsPerPage)
  for (let pageIndex = 0; pageIndex < pageCount; pageIndex++) {
    const pageStart = pageIndex * translationsPerPage
    const pageEnd = pageStart + translationsPerPage
    pages.push(props.translations.slice(pageStart, pageEnd))
  }

  // Don't try to open page 10 if there are only 2 pages
  const clampedCurrentPage = clamp(props.currentPage, 0, pageCount - 1)
  if (!props.languageCode) {
    return null
  }

  return (
    <div>
      <Table
        responsive="xl"
        className="table-striped table-bordered table-hover table-hover-cursor"
      >
        <thead className="thead-light">
          <tr>
            <th scope="col" className="minSmall" />
            <th scope="col">Original String</th>
            <th scope="col">Translated String</th>
            <th scope="col" className="min">
              Platform
            </th>
            <th scope="col" className="min">
              Added
            </th>
          </tr>
        </thead>
        <tbody>
          {pages[clampedCurrentPage].map((translation: TranslatableString) => {
            const translated = translation.translations[props.languageCode!!]
            let status: 'new' | 'flagged' | 'completed' = 'new'
            if (translated.flagged) {
              status = 'flagged'
            } else if (translated.translated) {
              status = 'completed'
            }
            const key = `${translation.platform} - ${translation.id} - ${
              translation.location
            } - ${translation.originalString}`
            return (
              <TranslationTableRow
                key={key}
                projectId={translation.platform}
                originalString={translation.originalString}
                createdDate={translation.submitted}
                translationId={translation.id}
                status={status}
                translatedString={translated.translatedString}
                onSelectTranslation={onSelectTranslation}
              />
            )
          })}
        </tbody>
      </Table>

      <PageIndicator
        currentPage={clampedCurrentPage}
        pageCount={pageCount}
        {...props}
      />
    </div>
  )
}

export default TranslationTable
